import { Typography } from '@mui/material';

import { UserRedirectsEnum } from '@services/UserService';

import type { BeneficiaryRedirectProps } from './types';

export const BENEFICIARY_REDIRECTS: BeneficiaryRedirectProps = {
  authenticateAttestation: {
    title: 'Autenticar atestado',
    mainButtonText: 'Acessar portal',
    redirectType: UserRedirectsEnum.AUTHENTICATE_ATTESTATION,
    content: (
      <Typography variant="text">
        Para acessar{' '}
        <Typography variant="text" fontWeight={700}>
          Autenticar atestado
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    ),
  },
  onlineCard: {
    title: 'Carteirinha digital',
    mainButtonText: 'Acessar portal',
    redirectType: UserRedirectsEnum.ONLINE_CARD,
    content: (
      <Typography variant="text">
        Para acessar sua{' '}
        <Typography variant="text" fontWeight={700}>
          Carteirinha digital
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    ),
  },
  suspendedBeneficiary: {
    title: 'Beneficiários suspensos',
    mainButtonText: 'Acessar portal',
    redirectType: UserRedirectsEnum.SUSPENDED_BENEFICIARY,
    content: (
      <Typography variant="text">
        Para acessar a{' '}
        <Typography variant="text" fontWeight={700}>
          Beneficiários suspensos
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    ),
  },
  beneficiaries: {
    title: 'Lista de beneficiários',
    mainButtonText: 'Acessar portal',
    redirectType: UserRedirectsEnum.BENEFICIARIES,
    content: (
      <Typography variant="text">
        Para acessar a{' '}
        <Typography variant="text" fontWeight={700}>
          Lista de beneficiários
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    ),
  },
};
