import styled from 'styled-components';
import { COLORS } from '../../themes/colors';
import { Grid } from '@mui/material';
import type { StyledGridProps } from './types';

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${COLORS.WHITE};
  padding: 18px 60px;
  box-sizing: border-box;

  span {
    font-size: 14px;
    white-space: nowrap;

    strong {
      color: ${COLORS.PRIMARY.BLUE.MAIN};
      margin-left: 10px;
    }
  }
`;

const StyledGrid = styled(Grid)<StyledGridProps>(({ hasClick }) => ({
  cursor: hasClick ? 'pointer' : 'default',
}));

export { Header, StyledGrid };
