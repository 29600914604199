import { Stack, Typography } from '@mui/material';

import { Icon } from '@hapvida/hapvida-core-components';

import { EditButton } from '@hapvida/hapvida-core-components/src/components/TableBodies/Checkbox/styles';

import type { PartnerCompanyProps } from '@services/PartnerCompanyService/dtos';

import { PartnerCompanyTypeChip, ProfileDetailsButton } from '@components';
import type { ListGridRowProps } from 'components/ListGrid/types';

import { PartnerCompanyListColumnNameEnum } from './index';
import { useSelectPartnerCompany } from '../hooks';
import { PLAN_TYPE_COLOR, PLAN_TYPE_NAME } from './contractTypeEnum';

export function getPartnerCompanyListRows(
  partnerCompanyList: PartnerCompanyProps[],
): ListGridRowProps[] {
  const { handleSelectPartnerCompany } = useSelectPartnerCompany();

  return partnerCompanyList.map(partnerCompanyData => {
    const {
      isMainPartnerCompany,
      mainPartnerCompany,
      partnerCompany,
      profile,
      planType,
      complement,
      legal,
    } = partnerCompanyData;

    const mainPartnerCompanyText = isMainPartnerCompany
      ? '-'
      : mainPartnerCompany;

    return {
      id: partnerCompanyData.partnerCompany,
      items: {
        [PartnerCompanyListColumnNameEnum.PARTNER_COMPANY]: (
          <Stack gap={0.5}>
            <Stack alignItems="center" flexDirection="row" gap={1}>
              <Typography fontWeight={700}>{partnerCompany}</Typography>
              <PartnerCompanyTypeChip
                fontSize="12px"
                isMainPartnerCompany={isMainPartnerCompany}
                size="small"
              />
            </Stack>
            {legal && (
              <Typography
                fontWeight={700}
                textTransform="uppercase"
                variant="text"
                title={legal}
              >
                {legal}
              </Typography>
            )}
            {complement && (
              <Typography fontWeight={400} variant="smallText">
                {complement}
              </Typography>
            )}
          </Stack>
        ),
        [PartnerCompanyListColumnNameEnum.MAIN_PARTNER_COMPANY]: (
          <Typography fontWeight={700}>{mainPartnerCompanyText}</Typography>
        ),
        [PartnerCompanyListColumnNameEnum.CONTRACT_TYPE]: planType ? (
          <Typography
            fontWeight={700}
            color={PLAN_TYPE_COLOR[planType]}
            variant="text"
          >
            {PLAN_TYPE_NAME[planType]}
          </Typography>
        ) : (
          <Typography fontWeight={700}>-</Typography>
        ),
        [PartnerCompanyListColumnNameEnum.PROFILE]: (
          <ProfileDetailsButton
            profileId={profile?.id ?? ''}
            profileName={profile?.name ?? ''}
          />
        ),
        [PartnerCompanyListColumnNameEnum.ACCESS_ACTION]: (
          <Stack alignSelf="flex-end" pr={3}>
            <EditButton
              onClick={() =>
                handleSelectPartnerCompany({
                  ...partnerCompanyData,
                  partnerCompany: partnerCompanyData.partnerCompany,
                })
              }
            >
              <Icon
                name="chevron-right"
                size={24}
                style={{
                  minWidth: '24px',
                }}
              />
            </EditButton>
          </Stack>
        ),
      },
    };
  });
}
