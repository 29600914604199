import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { UserService } from '@services/UserService';

import { toastifyApiErrors } from '@utils';

export function useRedirectReport() {
  const { enqueueSnackbar } = useSnackbar();

  const userService = useMemo(() => new UserService(), []);

  const redirectReport = async () => {
    try {
      const redirectUrl = await userService.getRedirectReport();

      window.open(redirectUrl, '_blank');
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  return { redirectReport };
}
