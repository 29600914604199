enum UserRedirectsEnum {
  ONLINE_CARD = 'online-card',
  AUTHENTICATE_ATTESTATION = 'authenticate-attestation',
  MOVEMENT_BY_SCREEN = 'movement-by-screen',
  INCOME_REPORT = 'income-report',
  INVOICE_ORDER_NUMBER = 'invoice-order-number',
  OPERATING_COST_REPORT = 'operating-cost-report',
  MORE_SERVICES = 'more-services',
  MONTHLY_FEE_PREVIEW_REPORT = 'monthly-fee-preview-report',
  REVIEWS_SCREEN_MOVEMENT = 'reviews-screen-movement',
  MOVEMENT_STATUS = 'movement-status',
  SUSPENDED_BENEFICIARY = 'suspended-beneficiary',
  BILLING = 'billing',
  COPARTICIPATION_PREVIEW_REPORT = 'coparticipation-preview-report',
  BILLING_TOP_SAUDE = 'billing-top-saude',
  BENEFICIARIES = 'beneficiaries',
}

interface RedirectUserResponseDTO {
  content: string;
}

export { RedirectUserResponseDTO, UserRedirectsEnum };
