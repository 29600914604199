import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import type { AlertBillModalProps } from './types';

export function AlertBillModal({
  openModal,
  toggleModal,
}: Readonly<AlertBillModalProps>) {
  return (
    <Modal
      type="default"
      icon="alert"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      title="COMUNICADO IMPORTANTE: Agora seu boleto Mensal e de Coparticipação são separados"
    >
      <Typography variant="text">
        <Typography variant="text" fontWeight={700}>
          Atenção, Cliente PME:
        </Typography>{' '}
        Desde janeiro/2025, é necessário efetuar o pagamento de{' '}
        <Typography variant="text" fontWeight={700}>
          dois boletos.
        </Typography>{' '}
        Confira os detalhes:
        <ul>
          <li>
            <Typography variant="text" fontWeight={700}>
              Boleto de Mensalidade
            </Typography>{' '}
            – referente ao valor do seu plano.
          </li>
          <li>
            <Typography variant="text" fontWeight={700}>
              Boleto de Coparticipação
            </Typography>{' '}
            – referente aos serviços utilizados.
          </li>
        </ul>
        Ambos os boletos permanecem com o mesmo vencimento.{' '}
        <Typography variant="text" fontWeight={700}>
          Lembre-se de pagar ambos os boletos dentro do prazo
        </Typography>{' '}
        para garantir a continuidade dos seus serviços!
      </Typography>
    </Modal>
  );
}
