import React from 'react';

import { Grid } from '@mui/material';

import HeaderMinimalLayout from '../layouts/HeaderMinimal';

import DisclaimerCard from '../DisclaimerCard';

import { Title, Content, Description, ErrorWrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import type { ErrorPageProps } from './types';

const ErrorPage: React.FC<ErrorPageProps> = ({
  user,
  image,
  title,
  logOut,
  imageAlt,
  loginRoute,
  description,
  redirectOptions,
  showBottomContent,
  svg,
  authenticateOptions: AuthenticateOptions,
  logoRedirectRoute,
}) => {
  const navigate = useNavigate();

  const handleGoToRedirectRoute = () => {
    if (!logoRedirectRoute) return;
    navigate(logoRedirectRoute);
  };

  const onClickLogo = logoRedirectRoute ? handleGoToRedirectRoute : undefined;

  return (
    <HeaderMinimalLayout
      onClickLogo={onClickLogo}
      user={user}
      logOut={logOut}
      loginRoute={loginRoute}
    >
      <ErrorWrapper>
        {svg ? svg : <img src={image} alt={imageAlt} />}
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ErrorWrapper>
      {showBottomContent && (
        <Content>
          {user ? (
            <RedirectOptions redirectOptions={redirectOptions} />
          ) : (
            <>{AuthenticateOptions && <AuthenticateOptions />}</>
          )}
        </Content>
      )}
    </HeaderMinimalLayout>
  );
};

const RedirectOptions: React.FC<Pick<ErrorPageProps, 'redirectOptions'>> = ({
  redirectOptions,
}) => {
  return (
    <Grid display="flex" flexDirection="column" alignItems="center" item>
      <Title>Nem tudo está perdido...</Title>
      <Grid display="flex" item marginTop={3.6} flexWrap="wrap">
        {redirectOptions?.map(
          ({ image, title, description, button }, index) => (
            <DisclaimerCard
              key={index}
              title={title}
              image={image}
              imageAlt={title}
              description={description}
              button={{
                text: button.text,
                redirectTo: button.redirectTo as string,
              }}
              customStyles={{ maxWidth: 256 }}
            />
          ),
        )}
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
