import { useNavigate } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';

import {
  Icon,
  Mask,
  Pill,
  COLORS,
  Company,
} from '@hapvida/hapvida-core-components';

import { BillType } from '@services/FinancialService/dtos/BillsListDTO';

import { BillDetailRouter } from '@flows/private/financial/pages/Detail/router';
import { getPillColor } from '@flows/private/financial/helpers/getPillColor';

import { Line, StyledGrid, ActionButton } from './styles';
import type { BillRowProps } from './types';

// TO-DO: funcionalidade alterada a pedido da área de negócios, prop onDownloadBill será necessária caso a funcionalidade seja reativada
export function BillRow({ bill }: Readonly<BillRowProps>) {
  const navigate = useNavigate();

  const { number, status, healthOperator, amount, billType, companyDocument } =
    bill;

  return (
    <Line container alignItems="center">
      <StyledGrid item xs={3}>
        <Stack>
          <Typography variant="body2">{number}</Typography>
          {companyDocument && (
            <Typography variant="body2">
              {Mask.cnpj(companyDocument)}
            </Typography>
          )}
        </Stack>
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Stack>
          <Typography variant="body2" fontWeight={700}>
            {Mask.currency(amount)}
          </Typography>
          <Typography variant="body2">{BillType[billType]}</Typography>
        </Stack>
      </StyledGrid>
      <StyledGrid item xs={3}>
        <Company company={healthOperator} />
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Pill color={getPillColor(status)} text={status} fitContent />
      </StyledGrid>
      <StyledGrid item xs={2}>
        <Stack
          justifyContent="end"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {/* TO-DO: funcionalidade ocultada a pedido da área de negócios
           <ActionButton onClick={() => onDownloadBill(bill.billingNumber)}>
            <Icon
              name="download"
              color={COLORS.MONOCHROMATIC.GRAY4}
              size={24}
            />
          </ActionButton> */}

          <ActionButton
            onClick={() => {
              navigate(
                `${BillDetailRouter.dynamicPath({
                  billNumber: bill.number,
                })}?company=${bill.companyId}&year=${bill.year}`,
              );
            }}
          >
            <Icon
              color={COLORS.MONOCHROMATIC.GRAY4}
              name="chevron-right"
              size={24}
            />
          </ActionButton>
        </Stack>
      </StyledGrid>
    </Line>
  );
}
