import { Grid } from '@mui/material';

import { useDrawerFilter } from '@hapvida/hapvida-core-components';

import { useAuth, useFilterParams } from '@hooks';
import {
  CompanyStatusDrawerFilter,
  FiltersDrawerButton,
  SearchField,
  SubHeader,
} from '@components';
import { UserType } from '@contexts';

import { useCompanySetupListState } from '../../hooks';

export function CompanySetupListSubHeader() {
  const { hasFilters } = useFilterParams();
  const { filterCount } = useDrawerFilter();
  const { companySetupListState } = useCompanySetupListState();
  const { user } = useAuth();

  const { isError, isLoading } = companySetupListState;

  const isBackoffice = user?.companyType === UserType.Backoffice;

  const columnBackofficeGrid = isBackoffice ? 10 : 12;

  const totalCompanySetupListLength = companySetupListState?.data?.total ?? 0;

  const isResultEmpty = totalCompanySetupListLength === 0;

  const isEmpty = isResultEmpty && !hasFilters;

  const disableButtonSearch = isLoading && hasFilters;

  const filtersDrawerButtonDisabled =
    !filterCount && (isLoading || isResultEmpty);

  if ((!hasFilters && isLoading) || isError || isEmpty) {
    return null;
  }

  return (
    <SubHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={columnBackofficeGrid}>
          <SearchField
            currentResultLength={totalCompanySetupListLength}
            placeholder="Buscar por nome, razão social, CNPJ ou código do contrato mãe/filho"
            disableSearch={disableButtonSearch}
          />
        </Grid>
        {isBackoffice && (
          <Grid item xs={12} sm={5} md={2}>
            <FiltersDrawerButton
              subtitle="Selecione como deseja filtrar as empresas"
              title="Filtrar empresas"
              disabled={filtersDrawerButtonDisabled}
            >
              <CompanyStatusDrawerFilter />
            </FiltersDrawerButton>
          </Grid>
        )}
      </Grid>
    </SubHeader>
  );
}
