import { Grid } from '@mui/material';

import { ListGridColumnProps, ListGridRowProps } from '../../types';

interface GridItemProps {
  row: ListGridRowProps;
  columns: ListGridColumnProps[];
  defaultColumnSizePerItem: number;
}

export function GridItem({
  row,
  columns,
  defaultColumnSizePerItem,
}: Readonly<GridItemProps>) {
  const keys = columns.map(column => column.id);

  return (
    <Grid container spacing={1} margin={0} width="100%">
      {keys.map(key => {
        const size =
          columns.find(column => column.id === key)?.gridSize ??
          defaultColumnSizePerItem;

        return (
          <Grid
            overflow="auto"
            key={key}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-start"
            xs={size}
            paddingX={1}
          >
            {row.items[key]}
          </Grid>
        );
      })}
    </Grid>
  );
}
