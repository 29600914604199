import { Grid } from '@mui/material';

import { ListGridColumnProps } from '../../types';

interface GridHeaderProps {
  columns: ListGridColumnProps[];
  defaultColumnSizePerItem: number;
}

export function GridHeader({
  columns,
  defaultColumnSizePerItem,
}: Readonly<GridHeaderProps>) {
  return (
    <Grid spacing={1} paddingX={3} container margin={0} width="100%">
      {columns.map(column => (
        <Grid
          overflow="auto"
          key={column.id}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-start"
          xs={column.gridSize ?? defaultColumnSizePerItem}
          paddingX={1}
        >
          {column.item}
        </Grid>
      ))}
    </Grid>
  );
}
