import { UserProfileEnum } from '@constants';
import { useAuth } from '@hooks';

/* TO-DO: remover pós equalização mongo e sigo
import { BeneficiariesSummary } from '../BeneficiariesSummary'; */
import { CompanySummary } from '../CompanySummary';
import { BillsSummary } from '../BillsSummary';
import { MovementsSummary } from '../MovementsSummary';
import { HomeQuickActions } from '../HomeQuickActions';
import { HomeBanner } from '../HomeBanner';

export function HomeItemsList() {
  const { selectedPartnerCompany } = useAuth();

  const { name = '' } = selectedPartnerCompany?.profile ?? {};

  const isFinancial = name === UserProfileEnum.FINANCIAL;
  const isMovement = name === UserProfileEnum.MOVEMENT;

  if (isMovement) {
    return (
      <>
        <HomeBanner />
        <CompanySummary />

        {/* TO-DO: remover pós equalização mongo e sigo
        <BeneficiariesSummary /> */}

        <MovementsSummary />
      </>
    );
  }

  if (isFinancial) {
    return (
      <>
        <HomeBanner />
        <CompanySummary />

        <BillsSummary />
      </>
    );
  }

  return (
    <>
      <HomeBanner />
      <CompanySummary />

      <HomeQuickActions />

      {/* TO-DO: remover pós equalização mongo e sigo
      <BeneficiariesSummary /> */}

      <BillsSummary />

      <MovementsSummary />
    </>
  );
}
