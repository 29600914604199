import { useCallback, useState } from 'react';

import { DataGrid } from '@hapvida/hapvida-core-components';

import { BillTypeEnum } from '@flows/private/financial/constants/billtypeEnum';

import { useBillData } from '../../hooks';
import { ButtonLink, OperationalCostModal } from './components';
import { useGridRows } from './helpers';
import type { BillDetailsCardProps } from './types';
import { ReportRedirectModal } from './components/ReportRedirectModal';

export function BillDetailsCard({
  bill,
  reportFiles,
}: Readonly<BillDetailsCardProps>) {
  const { commonData } = useBillData(bill);
  // const { fetchReportWithFileType } = useBillState();

  const [openOperationalCostModalState, setOpenOperationalCostModalState] =
    useState(false);
  const [openCarnetOrReportModalState, setOpenCarnetOrReportModalState] =
    useState(false);
  const [openReportMonthlyModalState, setOpenReportMonthlyModalState] =
    useState(false);

  const toggleOperationalModal = useCallback(
    () => setOpenOperationalCostModalState(!openOperationalCostModalState),
    [openOperationalCostModalState],
  );
  const toggleReportMonthlyModal = useCallback(
    () => setOpenReportMonthlyModalState(!openReportMonthlyModalState),
    [openReportMonthlyModalState],
  );
  const toggleCarnetOrReportModal = useCallback(
    () => setOpenCarnetOrReportModalState(!openCarnetOrReportModalState),
    [openCarnetOrReportModalState],
  );

  // const { csvFiles, pdfFiles, txtFiles } = reportFiles;

  const { billType } = bill;

  // const pdfReportState = fetchReportWithFileType('PDF', billingNumber);
  // const csvReportState = fetchReportWithFileType('CSV', billingNumber);
  // const txtReportState = fetchReportWithFileType('TXT', billingNumber);

  const isOperationalCost = billType === BillTypeEnum.OPERATIONAL_COST;
  const isCarnetComplement = billType === BillTypeEnum.CARNET_COMPLEMENT;

  // const handleOpenFile = (file?: FileData) => {
  //   if (file?.path) window.open(file.path, '_blank');
  // };

  // const handleOpenFiles = (files?: FileData[]) => {
  //   files?.forEach(file => handleOpenFile(file));
  // };

  const operationalCostRow = {
    label: 'Relatório',
    xs: 3,
    value: (
      <ButtonLink
        onClick={toggleOperationalModal}
        text="Acessar relatório"
        iconName="link"
      />
    ),
  };

  const reportMonthlyFilesRows = [
    {
      label: 'Relatório',
      xs: 3,
      value: (
        <ButtonLink
          onClick={toggleReportMonthlyModal}
          text="Acessar relatório"
          iconName="link"
        />
      ),
    },
  ];

  /**
   * REMINDER: Descomentar regra de report mensal e de copay futuramente quando for retirado o redirect
   */

  // const reportMonthlyFilesRows = [
  //   {
  //     label: 'Relatório analítico (PDF)',
  //     xs: 3,
  //     value: getReportFileRows('PDF', pdfReportState),
  //   },
  //   {
  //     label: 'Relatório analítico (CSV)',
  //     xs: 3,
  //     value: getReportFileRows('CSV', csvReportState),
  //   },
  //   {
  //     label: 'Relatório analítico (TXT)',
  //     xs: 3,
  //     value: getReportFileRows('TXT', txtReportState),
  //   },
  // ];

  // const isCarnetComplementRow = getReportFilesCarnetComplementRow({
  //   pdfFiles,
  //   csvFiles,
  //   txtFiles,
  //   handleOpenFiles,
  // });

  const isCarnetComplementRow = [
    {
      label: 'Relatório',
      xs: 3,
      value: (
        <ButtonLink
          onClick={toggleCarnetOrReportModal}
          text="Acessar relatório"
          iconName="link"
        />
      ),
    },
  ];

  const carnetOrReportRows = isCarnetComplement
    ? isCarnetComplementRow
    : reportMonthlyFilesRows;
  const newVisibleRows = isOperationalCost
    ? [operationalCostRow]
    : carnetOrReportRows;

  const gridRows = useGridRows(bill, newVisibleRows);

  return (
    <>
      <DataGrid
        rows={[...commonData, ...gridRows]}
        gridContainerProps={{ rowSpacing: 3 }}
      />
      <OperationalCostModal
        openModal={openOperationalCostModalState}
        toggleModal={toggleOperationalModal}
      />
      <ReportRedirectModal
        title="Relatório mensal"
        openModal={openReportMonthlyModalState}
        toggleModal={toggleReportMonthlyModal}
      />
      <ReportRedirectModal
        title="Relatório Carnet Complementar-coparticipação"
        openModal={openCarnetOrReportModalState}
        toggleModal={toggleCarnetOrReportModal}
      />
    </>
  );
}
