import { Typography } from '@mui/material';
import { useCallback } from 'react';

import { Modal } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

import type { FinancialRedirectModalProps } from './types';

export function BeneficiariesRedirectModal({
  openModal,
  toggleModal,
}: Readonly<FinancialRedirectModalProps>) {
  const { redirectUser } = useRedirectUser();

  const handleRedirect = useCallback(async () => {
    await redirectUser(UserRedirectsEnum.BENEFICIARIES);

    toggleModal();
  }, [toggleModal]);

  return (
    <Modal
      type="default"
      icon="alert"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      mainButtonText="Acessar portal"
      mainAction={handleRedirect}
      title="Lista de beneficiários"
    >
      <Typography variant="text">
        Para acessar a{' '}
        <Typography variant="text" fontWeight={700}>
          Lista de beneficiários
        </Typography>
        , clique em “Acessar portal” para ser direcionado para uma nova página.
      </Typography>
    </Modal>
  );
}
