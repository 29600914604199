import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string()
    .min(1, 'Preencha o nome do usuário')
    .required()
    .max(200, 'O nome do usuário deve ter no máximo 200 caracteres')
    .default(''),
  email: Yup.string()
    .min(1, '')
    .email('E-mail inválido.')
    .required()
    .default(''),
  phone: Yup.string()
    .min(1, 'Número de telefone inválido.')
    .required()
    .test('len', 'Número de telefone inválido.', val => {
      if (val) {
        return val.length === 15;
      }
      return false;
    })
    .default(''),
});
