import { useMediaQuery, useTheme } from '@mui/material';

import homeBannerDesk from '@assets/images/alteracao-1054x250-desk.png';
import homeBannerMobile from '@assets/images/alteracao-358x250-mob.png';
import homeBannerTablet from '@assets/images/alteracao-720x250-tablet.png';

import { BannerContainer } from './styles';
import { MESSAGE_HOME_REDIRECT } from './constants';

export function HomeBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleNavigate = () => {
    window.open(MESSAGE_HOME_REDIRECT, '_blank');
  };

  return (
    <BannerContainer role="button" tabIndex={0} onClick={handleNavigate}>
      {isMobile && (
        <img
          src={homeBannerMobile}
          alt="Imagem mobile do banner de redirect para mensagem de alerta"
        />
      )}
      {isTablet && (
        <img
          src={homeBannerTablet}
          alt="Imagem tablet do banner de redirect para mensagem de alerta"
        />
      )}
      {isDesktop && (
        <img
          src={homeBannerDesk}
          alt="Imagem desktop do banner de redirect para mensagem de alerta"
        />
      )}
    </BannerContainer>
  );
}
