import { Stack } from '@mui/material';

import { ErrorLoadingPage, Loading } from '@hapvida/hapvida-core-components';

import { useCompanyDetailsState } from './hooks';
import { CompanyDetailsCard } from './components/CompanyDetailsCard';
import { CompanyDetailsSummaryCard } from './components/CompanyDetailsSummaryCard';
/* TO-DO: remover pós equalização mongo e sigo
import { CompanyBeneficiariesSummaryCard } from './components/CompanyBeneficiariesSummaryCard'; */

export function CompanyDetailsLayout() {
  const { companyDetailsState, refreshCompanyDetailsStates } =
    useCompanyDetailsState();

  const hasError = companyDetailsState?.isError;

  if (hasError) {
    return <ErrorLoadingPage onReloadClick={refreshCompanyDetailsStates} />;
  }

  if (companyDetailsState?.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  const companyDetailsData = companyDetailsState?.data?.company;

  return (
    <Stack spacing={3} paddingY={3}>
      {/* TO-DO: remover pós equalização mongo e sigo
      <CompanyBeneficiariesSummaryCard />
      */}

      <CompanyDetailsSummaryCard
        companyDetailsSummaryData={companyDetailsData}
      />

      <CompanyDetailsCard companyDetailsData={companyDetailsData} />
    </Stack>
  );
}
