import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string()
    .min(1, '')
    .required()
    .max(200, 'O nome do usuário deve ter no máximo 200 caracteres')
    .default(''),
  email: Yup.string().email('E-mail inválido.').required().default(''),
  confirmedEmail: Yup.string()
    .email('Confirmação de e-mail inválida.')
    .required()
    .default('')
    .test(
      'email-matching',
      'Confirmação de e-mail inválida.',
      function emailConfirmed(valueEmail) {
        return valueEmail === this.parent.email;
      },
    ),
  phone: Yup.string()
    .required()
    .test('len', 'Número de telefone inválido.', val => {
      if (val) {
        return val.length === 15;
      }
      return false;
    })
    .default(''),
});
