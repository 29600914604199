import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { useConfirmation } from '@hapvida/hapvida-core-components';

import { AddAccountFormProps, UserService } from '@services/UserService';

import { handleProfiles, toastifyApiErrors } from '@utils';
import { UserQueryKeysEnum } from '@constants';
import { useAuth, useScreenLoading } from '@hooks';
import { UserListRouter } from '@flows/private/users/pages/UserList/router';
import { useAccessManagement } from 'components/ProfileSelectionCard/hooks';

export function useCreateUser() {
  const { selectedCompany } = useAuth();
  const { onScreenLoading } = useScreenLoading();
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { editedPartnerCompanies, availableProfilesState } =
    useAccessManagement();

  const handleAddNewUserSuccess = () => {
    showConfirmation({
      title: 'Acesso atrelado com sucesso',
      icon: 'check',
      confirmText: 'Prosseguir',
      onConfirm: () => navigate(UserListRouter.path),
      content: (
        <Typography variant="text">
          Enviamos um e-mail / WhatsApp de confirmação para o usuário.
        </Typography>
      ),
      type: 'success',
    });
  };

  const companyId = selectedCompany?.id;

  const handleAddAccount = async (data: AddAccountFormProps) => {
    try {
      if (companyId) {
        onScreenLoading(true);
        const createAccount = new UserService();
        await createAccount.createUser({
          companyId,
          profiles: handleProfiles(
            editedPartnerCompanies,
            availableProfilesState,
          ),
          ...data,
        });

        const usersQueryKey = [UserQueryKeysEnum.USERS_STATE];
        queryClient.removeQueries({ queryKey: usersQueryKey, exact: true });
        await queryClient.refetchQueries({
          queryKey: usersQueryKey,
        });

        handleAddNewUserSuccess();
      }
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    } finally {
      onScreenLoading(false);
    }
  };

  return { handleAddAccount };
}
