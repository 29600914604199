import { useNavigate } from 'react-router-dom';

import { Collapse } from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';
import { BillDetailRouter } from '@flows/private/financial/pages/Detail/router';
import { BillsListColumns } from '@flows/private/financial/constants/columns';
import { ITEMS_LIMIT_PER_PAGE } from '@constants';

import type { BillsListCardProps } from './types';
import { createBillTableRows } from '../../helpers/createBillTableRows';

export function BillsListCard({ billsList }: Readonly<BillsListCardProps>) {
  const { handleTableOrderChange, handlePageChange, currentPage, tableOrder } =
    useFilterParams();
  const navigate = useNavigate();

  /* TO-DO: funcionalidade ocultada a pedido da área de negócios
  const { handleDownload, handleDownloadAll } = useHandleDownload({
    billsList,
  }); */

  const handleChangePage = (_event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleDetail = (billId: string) => {
    const currentBill = billsList.bills.find(
      bill => Number(billId) === Number(bill.billingNumber),
    )!;

    navigate(
      `${BillDetailRouter.dynamicPath({
        billNumber: currentBill.number,
      })}?company=${currentBill.companyId}&year=${currentBill.year}`,
    );
  };

  const billTableRows = createBillTableRows(billsList);

  return (
    <Collapse
      from={Math.floor(
        billsList.bills.length % ((currentPage + 1) * ITEMS_LIMIT_PER_PAGE),
      )}
      onOrderChange={handleTableOrderChange}
      count={Math.ceil(billsList.total / ITEMS_LIMIT_PER_PAGE)}
      baseTableProps={{
        tableContainerHeight: '100%',
      }}
      onPageChange={handleChangePage}
      data={billTableRows}
      TableHeadColumns={BillsListColumns}
      currentPage={currentPage}
      tableOrder={tableOrder}
      onEdit={handleDetail}
      to={billsList.total}
      /* TO-DO: funcionalidade alterada a pedido da área de negócios, caso volte a ser um CheckboxBody retornar valores
      // checkAllLabel="Selecionar todas as faturas"
      // allSelectedAction={handleDownloadAll}
      // onDownload={handleDownload}
      // columns={BillsListColumns}
      // onDetail={handleDetail}
      // footerLabel="faturas"
      */
    />
  );
}
