import React from 'react';

import { Button, ErrorPage } from '@hapvida/hapvida-core-components';

import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

import SessionExpiredImage from '@assets/images/session-expired-error.svg';

export const SessionExpired: React.FC = () => {
  const handleClearCacheSessionStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
  };
  const description = (
    <>
      Todas as tarefas que <b>não foram concluídas não serão salvas</b>. <br />
      Entre novamente para retormar suas tarefas. <br />
      <a href={process.env.REACT_APP_LOGIN_URL}>
        <Button
          variant="contained"
          sx={{
            marginTop: 3,
            backgroundColor: COLORS.PRIMARY.BLUE.MAIN,
          }}
          onClick={handleClearCacheSessionStorage}
        >
          Efetuar login
        </Button>
      </a>
    </>
  );

  return (
    <ErrorPage
      image={SessionExpiredImage}
      imageAlt="Sessão expirada"
      title="Oops! Sessão expirada."
      description={description}
      loginRoute={process.env.REACT_APP_LOGIN_URL}
    />
  );
};
