import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

import type { InvoiceModalProps } from './types';

export function InvoiceModal({
  openModal,
  toggleModal,
}: Readonly<InvoiceModalProps>) {
  const { redirectUser } = useRedirectUser();

  const handleRedirectLegacyPortal = () => {
    redirectUser(UserRedirectsEnum.INVOICE_ORDER_NUMBER);
  };
  return (
    <Modal
      type="default"
      icon="alert"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      mainButtonText="Acessar portal"
      mainAction={handleRedirectLegacyPortal}
      title="Nota fiscal"
    >
      <Typography variant="text">
        A funcionalidade de{' '}
        <Typography variant="text" fontWeight={700}>
          Nota fiscal
        </Typography>{' '}
        estará disponível em breve. No momento, você pode visualizar os dados da
        nota em uma nova guia.
      </Typography>
    </Modal>
  );
}
