import { Stack, styled } from '@mui/material';

export const BannerContainer = styled(Stack)`
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;
