import { Typography } from '@mui/material';

import { Modal } from '@hapvida/hapvida-core-components';

import { useRedirectReport } from 'hooks/useRedirectReport';

import type { ReportRedirectModalProps } from './types';

export function ReportRedirectModal({
  title,
  openModal,
  toggleModal,
}: Readonly<ReportRedirectModalProps>) {
  const { redirectReport } = useRedirectReport();

  const handleRedirectLegacyPortal = () => {
    redirectReport();
  };
  return (
    <Modal
      type="default"
      icon="alert"
      open={openModal}
      handleCloseModal={toggleModal}
      secondaryButtonText="Fechar"
      mainButtonText="Acessar portal"
      mainAction={handleRedirectLegacyPortal}
      title={title}
    >
      <Typography variant="text">
        A funcionalidade de{' '}
        <Typography variant="text" fontWeight={700}>
          {title}
        </Typography>{' '}
        estará disponível em breve. No momento, você pode visualizar os dados do
        relatório em uma nova guia.
      </Typography>
    </Modal>
  );
}
