export default interface IDragAndDropFile {
  path: string;
  fileName: string;
  size: string | number;
  type?: string;
  lines?: number;
  uploadName?: string;
  updatedAt?: string;
  date: string;
  createdAt?: string;
}

export enum FirefoxCsvXlsEnum {
  TEXT_CSV = 'text/csv',
  VND_MS_EXCEL = 'application/vnd.ms-excel',
}

export enum ExtensionNameEnum {
  CSV = 'csv',
  XLS = 'xls',
  TXT = 'TXT',
  UNKNOWN = 'UNKNOWN',
}

export enum InternalErrorsEnum {
  FILE_TOO_LARGE = 'file-too-large',
  FILE_INVALID_TYPE = 'file-invalid-type',
}

export const ERROR_MESSAGES: Record<InternalErrorsEnum, string> = {
  [InternalErrorsEnum.FILE_TOO_LARGE]:
    'Seu arquivo ultrapassa o tamanho máximo.',
  [InternalErrorsEnum.FILE_INVALID_TYPE]:
    'Parece que você está tentando subir um arquivo com formato diferente do permitido.',
};
