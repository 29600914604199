import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useRedirectUser } from 'hooks/useRedirectUser';

import { SidebarModalDataProps, SidebarModalStateContextData } from './types';

const SidebarModalStateContext = createContext<SidebarModalStateContextData>(
  {} as SidebarModalStateContextData,
);

function SidebarModalStateProvider({
  children,
}: Readonly<PropsWithChildren<{}>>) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<SidebarModalDataProps>();
  const [currentModalPath, setCurrentModalPath] = useState<string>();
  const { redirectUser } = useRedirectUser();

  const handleShowModal = (data: SidebarModalDataProps, modalPath: string) => {
    setCurrentModalPath(modalPath);
    setModalData(data);
    setModalOpen(true);
  };

  const closeAndCleanModal = () => {
    setCurrentModalPath(undefined);
    setModalOpen(false);
  };

  const handleCloseModal = () => {
    if (modalData?.onClose) {
      modalData.onClose();
    }
    closeAndCleanModal();
  };

  const handleMainAction = () => {
    if (modalData?.mainAction) {
      modalData.mainAction();
    }
    closeAndCleanModal();
  };

  const handleRedirectAction = async () => {
    if (modalData?.redirectType) {
      await redirectUser(modalData.redirectType);
    }
    closeAndCleanModal();
  };

  const valueContextProvider = useMemo(
    () => ({
      handleShowModal,
      handleCloseModal,
      modalOpen,
      modalData,
      handleMainAction,
      currentModalPath,
      handleRedirectAction,
    }),
    [
      handleShowModal,
      handleCloseModal,
      modalOpen,
      modalData,
      handleMainAction,
      currentModalPath,
      handleRedirectAction,
    ],
  );

  return (
    <SidebarModalStateContext.Provider value={valueContextProvider}>
      {children}
    </SidebarModalStateContext.Provider>
  );
}

function useSidebarModalState() {
  const context = useContext(SidebarModalStateContext);

  if (!context) {
    throw new Error(
      'useSidebarModalState must be used within SidebarModalStateProvider',
    );
  }

  return context;
}

export { useSidebarModalState, SidebarModalStateProvider };
