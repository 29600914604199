import { Mask } from '@hapvida/hapvida-core-components';

import { HapvidaApi } from '@http';
import { generateUrlParams } from '@utils';

import {
  CreateBackofficeUserRequestDTO,
  DeleteUserRequestDTO,
  CreateUserResponseDTO,
  CreateUserRequestDTO,
  UpdateUserResponse,
  UpdateUserRequest,
  UsersResponseDTO,
  GetUserResponse,
  ProfileDetailsResponseDTO,
  ProfileProps,
  GetAccountVersionEnum,
  GetCompanyAccountsRequestDTO,
  GetBackofficeAccountsRequestDTO,
  UserRedirectsEnum,
  RedirectUserResponseDTO,
  GetProfilesDTO,
  GetProfilesResponseDTO,
  GetProfilesHierarchyResponseDTO,
  GetUserPartnerCompaniesRequestDTO,
  GetUserPartnerCompaniesResponseDTO,
  UpdateBackofficeUserRequest,
} from './dtos';
import {
  ValidateExistUserRequestDTO,
  ValidateExistUserResponseDTO,
} from './dtos/ValidateExistUserDTO';

export * from './dtos';

export class UserService {
  private readonly endpoints = {
    getAccounts: (version: GetAccountVersionEnum) => `/api/${version}/users`,
    getUserDetail: (userId: string, companyId: string) =>
      `/api/v2/users/${userId}/company/${companyId}`,
    getBackofficeUserDetail: (userId: string) => `/api/v1/users/${userId}`,
    updateUser: (id: string) => `/api/v2/users/${id}`,
    createUser: () => `/api/v2/users/pending`,
    createBackofficeUser: () => `/api/v1/users/backoffice`,
    updateBackofficeUser: (id: string) => `/api/v1/users/${id}`,
    resendConfirmation: (id: string) =>
      `/api/v1/users/${id}/confirmation-resend`,
    deleteUser: (userId: string, companyId: string) =>
      `api/v1/users/${userId}/remove-company/${companyId}`,
    backofficeDeleteUser: (userId: string, companyId: string) =>
      `api/v1/users/${userId}/remove-company/${companyId}/backoffice`,
    profileDetails: (id: string) => `/api/v1/profiles/${id}`,
    profiles: () => '/api/v1/profiles',
    profilesHierarchy: () => '/api/v1/profiles/hierarchy',
    validateExistUser: (email: string, companyId: string) =>
      `/api/v1/users/${email}/company/${companyId}`,
    getRedirect: (redirectType: UserRedirectsEnum, queryString: string = '') =>
      `/api/v1/users/redirect/${redirectType}/sigo${queryString}`,
    reprocessUser: (userId: string) => `/api/v1/users/reprocess/${userId}`,
    getUserPartnerCompanies: (companyId: string, userId?: string) =>
      `api/v1/users/${
        userId ? `${userId}/` : ''
      }companies/${companyId}/partner-companies`,
    getRedirectReport: () => `/api/v1/users/redirect/billing/report-download`,
  };

  private async getAccounts(
    params: URLSearchParams,
    version: GetAccountVersionEnum,
  ) {
    const { data } = await HapvidaApi.get<UsersResponseDTO>(
      this.endpoints.getAccounts(version),
      {
        params,
      },
    );
    return data.content;
  }

  public async getCompanyAccounts({
    companyId,
    partnerCompany,
    ...options
  }: GetCompanyAccountsRequestDTO) {
    const paramsObject = {
      'company-id': companyId,
      'partner-company': partnerCompany,
      ...options,
    };
    const params = generateUrlParams(paramsObject);
    return this.getAccounts(params, GetAccountVersionEnum.Company);
  }

  public async getBackofficeAccounts({
    companyId,
    ...options
  }: GetBackofficeAccountsRequestDTO) {
    const paramsObject = {
      'company-id': companyId,
      ...options,
    };
    const params = generateUrlParams(paramsObject);
    return this.getAccounts(params, GetAccountVersionEnum.Backoffice);
  }

  public async getUserDetail(
    userId: string,
    companyId: string,
  ): Promise<GetUserResponse> {
    const { data } = await HapvidaApi.get<GetUserResponse>(
      this.endpoints.getUserDetail(userId, companyId),
    );

    return data;
  }

  public async getBackofficeUserDetail(
    userId: string,
  ): Promise<GetUserResponse> {
    const { data } = await HapvidaApi.get<GetUserResponse>(
      this.endpoints.getBackofficeUserDetail(userId),
    );

    return data;
  }

  public async updateBackofficeUser(
    userId: string,
    { document, email, name, phone, profile }: UpdateBackofficeUserRequest,
  ): Promise<UpdateUserResponse> {
    const { data } = await HapvidaApi.put<UpdateUserResponse>(
      this.endpoints.updateBackofficeUser(userId),
      {
        name,
        email,
        phone: Mask.numbers(phone),
        profile,
        document: Mask.numbers(document),
      },
    );

    return data;
  }

  public async updateUser(
    userId: string,
    { document, email, name, phone, profiles, companyId }: UpdateUserRequest,
  ): Promise<UpdateUserResponse> {
    const { data } = await HapvidaApi.put<UpdateUserResponse>(
      this.endpoints.updateUser(userId),
      {
        name,
        email,
        companyId,
        phone: Mask.numbers(phone),
        profiles,
        document: Mask.numbers(document),
      },
    );

    return data;
  }

  public async createUser({
    companyId,
    name,
    phone,
    email,
    confirmedEmail,
    profiles,
  }: CreateUserRequestDTO): Promise<CreateUserResponseDTO> {
    const { data } = await HapvidaApi.post<CreateUserResponseDTO>(
      this.endpoints.createUser(),
      {
        companyId,
        name,
        email,
        confirmedEmail,
        profiles,
        phone: Mask.numbers(phone),
      },
    );
    return data;
  }

  public async createBackofficeUser({
    name,
    phone,
    email,
    confirmedEmail,
  }: CreateBackofficeUserRequestDTO): Promise<CreateUserResponseDTO> {
    const { data } = await HapvidaApi.post<CreateUserResponseDTO>(
      this.endpoints.createBackofficeUser(),
      {
        name,
        email,
        confirmedEmail,
        phone: Mask.numbers(phone),
      },
    );
    return data;
  }

  public async resendConfirmation(userId: string) {
    const { data } = await HapvidaApi.patch(
      this.endpoints.resendConfirmation(userId),
    );

    return data;
  }

  public async deleteUser({ userId, companyId }: DeleteUserRequestDTO) {
    const { data } = await HapvidaApi.patch(
      this.endpoints.deleteUser(userId, companyId),
    );

    return data;
  }

  public async backofficeDeleteUser({
    userId,
    companyId,
  }: DeleteUserRequestDTO) {
    const { data } = await HapvidaApi.patch(
      this.endpoints.backofficeDeleteUser(userId, companyId),
    );

    return data;
  }

  public async getProfileDetails(profileId: string): Promise<ProfileProps> {
    const { data } = await HapvidaApi.get<ProfileDetailsResponseDTO>(
      this.endpoints.profileDetails(profileId),
    );

    return data.content;
  }

  public async getProfiles(): Promise<GetProfilesDTO> {
    const { data } = await HapvidaApi.get<GetProfilesResponseDTO>(
      this.endpoints.profiles(),
    );
    return data.content;
  }

  public async getProfilesHierarchy(): Promise<ProfileProps[]> {
    const { data } = await HapvidaApi.get<GetProfilesHierarchyResponseDTO>(
      this.endpoints.profilesHierarchy(),
    );
    return data.content;
  }

  public async validateExistUser({
    email,
    companyId,
  }: ValidateExistUserRequestDTO) {
    const { data } = await HapvidaApi.get<ValidateExistUserResponseDTO>(
      this.endpoints.validateExistUser(email, companyId),
    );
    return data;
  }

  public async getRedirect(
    redirectType: UserRedirectsEnum,
    queryString?: string,
  ) {
    const { data } = await HapvidaApi.get<RedirectUserResponseDTO>(
      this.endpoints.getRedirect(redirectType, queryString),
    );

    return data.content;
  }

  public async getRedirectReport() {
    const { data } = await HapvidaApi.get<RedirectUserResponseDTO>(
      this.endpoints.getRedirectReport(),
    );

    return data.content;
  }

  public async reprocessUser(userId: string) {
    await HapvidaApi.post(this.endpoints.reprocessUser(userId));
  }

  public async getUserPartnerCompanies({
    userId,
    companyId,
    ...rest
  }: GetUserPartnerCompaniesRequestDTO) {
    const { data } = await HapvidaApi.get<GetUserPartnerCompaniesResponseDTO>(
      this.endpoints.getUserPartnerCompanies(companyId, userId),
      {
        params: rest,
      },
    );
    return data.content;
  }
}
