import { useCallback, useState } from 'react';

import { SimpleFile } from '@hapvida/hapvida-core-components';

import type { DataGridItem } from '@hapvida/hapvida-core-components/src/components/DataGrid';

import type { BillDetails } from '@services/FinancialService';

import { useRedirectUser } from '@hooks';
import { getBillingRedirect } from '@flows/private/financial/helpers/getBillingRedirect';

import { DiscountLink, ButtonLink } from '../components';
import { InvoiceModal } from '../components/InvoiceModal';

export const useGridRows = (
  bill: BillDetails,
  reportFilesRows: DataGridItem[],
): DataGridItem[] => {
  const { redirectUser } = useRedirectUser();
  const [openInvoiceModalState, setOpenInvoiceModalState] = useState(false);

  const handleRedirectBillLegacyPortal = () => {
    const redirect = getBillingRedirect(bill?.period);
    redirectUser(redirect, `?billing-number=${bill?.billingNumber}`);
  };
  const toggleInvoiceModal = useCallback(
    () => setOpenInvoiceModalState(!openInvoiceModalState),
    [openInvoiceModalState],
  );

  return [
    {
      label: 'Boleto',
      xs: 3,
      value: (
        <SimpleFile
          onDownload={handleRedirectBillLegacyPortal}
          filename="Boleto"
        />
      ),
    },
    {
      label: 'Nota Fiscal',
      xs: 3,
      value: (
        <>
          <ButtonLink
            onClick={toggleInvoiceModal}
            text="Acessar nota fiscal"
            iconName="link"
          />
          <InvoiceModal
            openModal={openInvoiceModalState}
            toggleModal={toggleInvoiceModal}
          />
        </>
      ),
    },
    {
      label: 'Carta de desconto',
      xs: 6,
      value: <DiscountLink />,
    },
    ...reportFilesRows,
  ];
};
