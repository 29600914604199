import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { SummaryCardNew } from '@hapvida/hapvida-core-components';

import { MovimentationStepRouter } from '@flows/private/movements/pages/MovimentationSteps/router';
import { BillListRouter } from '@flows/private/financial/pages/List/router';
import { MOVIMENTATION_STEPS_KEYS } from 'constants/private/movements/newMovementSteps';

import QuickActionButton from './components/QuickActionButton';
import { BeneficiariesRedirectModal } from './components/BeneficiariesRedirectModal';

export function HomeQuickActions() {
  const navigate = useNavigate();

  const [openModalState, setOpenModalState] = useState(false);

  const toggleModal = () => {
    setOpenModalState(!openModalState);
  };

  const handleGoToBeneficiaries = () => {
    setOpenModalState(true);
  };

  const handleNewLayoutMovement = () => {
    navigate(
      MovimentationStepRouter.dynamicPath({
        movimentationStep: MOVIMENTATION_STEPS_KEYS.MOVIMENTATION_FILE,
      }),
    );
  };

  const handleGoToBillList = () => {
    navigate(BillListRouter.path);
  };

  return (
    <SummaryCardNew.Container>
      <Typography variant="subtitle1">Ações rápidas</Typography>

      <Stack gap={3} flexDirection="row" flexWrap="wrap">
        <QuickActionButton
          onClick={handleGoToBeneficiaries}
          text="Lista de beneficiários"
          iconName="clipboard-pulse"
        />

        <QuickActionButton
          onClick={handleNewLayoutMovement}
          text="Movimentação via layout"
          iconName="file-excel"
        />

        <QuickActionButton
          onClick={handleGoToBillList}
          text="Extrato financeiro"
          iconName="money"
        />
      </Stack>

      <BeneficiariesRedirectModal
        openModal={openModalState}
        toggleModal={toggleModal}
      />
    </SummaryCardNew.Container>
  );
}
