import { UserRedirectsEnum } from '@services/UserService';

import { BILLING_HISTORY_DATE } from '../constants/billingHistoryDate';

export function getBillingRedirect(period: string) {
  const periodDate = new Date(period);
  const billingHistoryDate = new Date(BILLING_HISTORY_DATE);
  const redirect =
    periodDate < billingHistoryDate
      ? UserRedirectsEnum.BILLING_TOP_SAUDE
      : UserRedirectsEnum.BILLING;
  return redirect;
}
