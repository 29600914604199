import React from 'react';
import Header from '../../HeaderMinimal';
import { Children, Wrapper } from './styles';
import type { HeaderMinimalLayoutProps } from './types';

const HeaderMinimalLayout: React.FC<HeaderMinimalLayoutProps> = ({
  children,
  user,
  logOut,
  loginRoute,
  onClickLogo,
}) => {
  return (
    <Wrapper>
      <Header
        onClickLogo={onClickLogo}
        user={user}
        logOut={logOut}
        loginRoute={loginRoute}
      />
      <Children>{children}</Children>
    </Wrapper>
  );
};

export default HeaderMinimalLayout;
