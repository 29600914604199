import { Mask, Pill } from '@hapvida/hapvida-core-components';

import { BillType } from '@services/FinancialService/dtos/BillsListDTO';
import type { Bill } from '@services/FinancialService/dtos/BillsListDTO';
import type { BillListProps } from '@services/FinancialService';

import { BillsListSortNameEnum } from '@flows/private/financial/constants/columns';
import Identifier from '@flows/private/financial/components/Identifier';
import SimpleText from '@flows/private/financial/components/SimpleText';
import { getPillColor } from '@flows/private/financial/helpers/getPillColor';

// TO-DO: funcionalidade alterada a pedido da área de negócios, tipagem de retorno original => (billsList: BillListProps): BillTableRow[]
export function createBillTableRows(billsList: BillListProps) {
  function convertBillMonthToCase(billMonth?: string): string {
    if (billMonth) {
      return (
        billMonth.charAt(0).toUpperCase() + billMonth.toLowerCase().slice(1)
      );
    }
    return '-';
  }

  return billsList?.bills?.map((bill: Bill) => {
    const main = {
      [BillsListSortNameEnum.NUMBER]: <Identifier bill={bill} />,

      [BillsListSortNameEnum.DUE_DATE]: (
        <SimpleText text={new Date(bill?.dueDate).toLocaleDateString()} />
      ),
      [BillsListSortNameEnum.MONTH]: (
        <SimpleText text={convertBillMonthToCase(bill?.month)} />
      ),
      [BillsListSortNameEnum.AMOUNT]: (
        <SimpleText
          text={Mask.currency(bill?.amount).toString()}
          fontWeight={700}
        />
      ),
      [BillsListSortNameEnum.STATUS]: (
        <Pill
          text={bill?.status}
          color={getPillColor(bill?.status)}
          fitContent
        />
      ),
      [BillsListSortNameEnum.TYPE]: BillType[bill?.billType],
    };

    return {
      id: String(bill?.billingNumber),
      main,
      // TO-DO: funcionalidade alterada a pedido da área de negócios, valor dependents n será necessário caso a funcionalidade seja reativada
      dependents: [],
    };
  });
}
