export const magicNumberMap: { [key: string]: Uint8Array | number[] } = {
  PDF: Uint8Array.from([0x25, 0x50, 0x44, 0x46]),
  PNG: Uint8Array.from([0x89, 0x50, 0x4e, 0x47]),
  JPEG: Uint8Array.from([0xff, 0xd8, 0xff]),
  JPG: Uint8Array.from([0xff, 0xd8, 0xff]),
  GIF: Uint8Array.from([0x47, 0x49, 0x46, 0x38]),
  DOC: Uint8Array.from([0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1]),
  PPT: Uint8Array.from([0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1]),
  XLS: Uint8Array.from([0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1]),
  ZIP: Uint8Array.from([0x50, 0x4b, 0x03, 0x04]),
  EXE: Uint8Array.from([0x4d, 0x5a]),
  EXE_NE: Uint8Array.from([0x4e, 0x45]),
  EXE_LE: Uint8Array.from([0x4c, 0x45]),
  EXE_LX: Uint8Array.from([0x4c, 0x58]),
  EXE_TXT: Uint8Array.from([0x4d, 0x5a, 0x90, 0x00]),
  TXT: Uint8Array.from([]),
};
