import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { Base64File } from '../../utils/base64File';
import { formatBytes } from '../../utils/formatBytes';
import Icon from '../Icon';
import {
  ActionArea,
  ActionButton,
  ActionIcon,
  ArchiveArea,
  ArchiveDescription,
  ArchiveIcon,
  ArchiveTitle,
  Container,
  Description,
  DividerArea,
  DragFileText,
  DropArea,
  ErrorArea,
  ErrorIcon,
  ErrorMessage,
  Heading,
  InfoItem,
  InfoStack,
  InnerContainer,
  ProcessedFileErrorMessageArea,
  RightInfo,
  SelectFileText,
  SubmittingContent,
  SubmittingText,
  Title,
} from './styles';

import * as mime from 'mime-types';
import formatDateISO from '../../utils/formatDateISO';
import { truncateString } from '../../utils/truncateString';
import { PulsarAnimationLoading } from '../PulsarAnimation';
import { SummaryCardNew } from '../SummaryCardNew';
import IDragAndDropFile, {
  ERROR_MESSAGES,
  ExtensionNameEnum,
  InternalErrorsEnum,
} from './dtos/IDragDropFile';
import { magicNumberMap } from './constants';

interface MultipleFiles {
  files: IDragAndDropFile[];
  fileChanged?: IDragAndDropFile;
}

export interface DragAndDropProps<
  Multiple extends boolean | undefined = undefined,
> {
  value: Multiple extends true
    ? IDragAndDropFile[]
    : IDragAndDropFile | undefined;
  onChange: (
    file: Multiple extends true ? MultipleFiles : IDragAndDropFile | undefined,
    error?: string,
  ) => void | Promise<void>;
  title?: string;
  multiple?: Multiple;
  maxSize?: number;
  acceptedTypes?: string | string[];
  model?: string;
  errorMessage?: ReactNode;
  isAvatar?: boolean;
  children?: ReactNode;
  onDelete?(file: IDragAndDropFile): Promise<void>;
  hideEditButton?: boolean;
  onDownloadFileItem?(file: IDragAndDropFile): Promise<void | String>;
  showHeader?: boolean;
  isSubmittingFiles?: boolean;
  isValidatingFiles?: boolean;
  itHasError?: boolean;
  processedFileError?: ReactNode | undefined;
  rightInfo?: string;
  containerStyles?: Object;
  dropAreaStyles?: Object;
  description?: ReactNode;
  hideFileSize?: boolean;
}

const DragAndDrop = <Multiple extends boolean = false>({
  title,
  value,
  onChange,
  multiple,
  maxSize = 8e6, // 8MB
  acceptedTypes: acceptedTypesAny,
  errorMessage,
  isAvatar,
  children,
  onDelete,
  onDownloadFileItem,
  hideEditButton,
  isSubmittingFiles = false,
  isValidatingFiles = false,
  itHasError = false,
  processedFileError,
  rightInfo,
  containerStyles,
  dropAreaStyles,
  description,
  hideFileSize = false,
}: DragAndDropProps<Multiple>) => {
  const isFirefox = /Firefox/i.test(navigator.userAgent);

  const [replacingState, setReplacingState] = useState<number>();
  const [internalErrorState, setInternalErrorState] = useState<string>();

  const error = useMemo(
    () => errorMessage ?? internalErrorState,
    [errorMessage, internalErrorState],
  );

  const { breakpoints } = useTheme();

  const acceptedTypesCsvXls = (
    fileType: string,
    acceptTypes?: string | string[],
  ) => {
    if (typeof acceptTypes === 'string') {
      return acceptTypes.includes(fileType);
    }

    if (Array.isArray(acceptTypes)) {
      return acceptTypes.some(
        type => typeof type === 'string' && type.includes(fileType),
      );
    }

    return false;
  };

  const { mimeTypes, fileTypes } = useMemo(() => {
    if (!acceptedTypesAny)
      return { mimeTypes: undefined, fileTypes: undefined };

    const array = Array.isArray(acceptedTypesAny)
      ? acceptedTypesAny
      : [acceptedTypesAny];

    const firefoxAcceptedTypesCSVXLS = [...array];

    const mimeTypesNormal = array.map(type => mime.lookup(type) || type);
    const mimeFirefoxCSVXLS = firefoxAcceptedTypesCSVXLS.map(
      type => mime.lookup(type) || type,
    );
    const fileTypes = mimeTypesNormal.map(
      type => mime.extension(type) as string,
    );
    const hasFileCSVXLS =
      acceptedTypesCsvXls(ExtensionNameEnum.CSV, acceptedTypesAny) ||
      acceptedTypesCsvXls(ExtensionNameEnum.XLS, acceptedTypesAny);

    const mimeTypes =
      isFirefox && hasFileCSVXLS ? mimeFirefoxCSVXLS : mimeTypesNormal;

    return { mimeTypes, fileTypes };
  }, [acceptedTypesAny]);

  useEffect(() => {
    if (typeof multiple === 'number' && multiple < 2) {
      throw new Error(
        "Prop 'multiple' must be false, true, or a number greater than 1.",
      );
    }
  }, [multiple]);

  const hasFile = Array.isArray(value) ? value.length > 0 : !!value?.fileName;
  const files = useMemo<IDragAndDropFile[]>(() => {
    if (Array.isArray(value)) return value;
    if (value?.fileName !== undefined) return [value];
    return [];
  }, [value]);

  const separeteCSVXLSFirefox = (
    fileContent: IDragAndDropFile[],
    errors:
      | InternalErrorsEnum.FILE_INVALID_TYPE
      | InternalErrorsEnum.FILE_TOO_LARGE
      | undefined,
  ) => {
    if (!isFirefox) {
      return fileContent;
    }

    if (errors !== undefined) {
      setInternalErrorState(ERROR_MESSAGES[errors]);
      return [];
    }

    const hasCSVType = acceptedTypesCsvXls(ExtensionNameEnum.CSV, fileTypes);
    const hasXLSType = acceptedTypesCsvXls(ExtensionNameEnum.XLS, fileTypes);

    const fileName = fileContent[0].fileName;

    if (hasCSVType && hasXLSType) {
      return fileContent;
    }

    if (hasCSVType) {
      if (
        fileName.includes(ExtensionNameEnum.CSV) ||
        !fileName.includes(ExtensionNameEnum.XLS)
      ) {
        return fileContent;
      } else if (!fileName.includes(ExtensionNameEnum.CSV)) {
        setInternalErrorState(
          ERROR_MESSAGES[InternalErrorsEnum.FILE_INVALID_TYPE],
        );
        return [];
      }
    }

    if (hasXLSType) {
      if (
        fileName.includes(ExtensionNameEnum.XLS) ||
        !fileName.includes(ExtensionNameEnum.CSV)
      ) {
        return fileContent;
      } else if (!fileName.includes(ExtensionNameEnum.XLS)) {
        setInternalErrorState(
          ERROR_MESSAGES[InternalErrorsEnum.FILE_INVALID_TYPE],
        );
        return [];
      }
    }

    return fileContent;
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const error = rejectedFiles
        .flatMap(file => file.errors.map(error => error.code))
        .find(value => Object.keys(ERROR_MESSAGES).includes(value)) as
        | keyof typeof ERROR_MESSAGES
        | undefined;

      setInternalErrorState(ERROR_MESSAGES[error!] ?? error);

      const fileBase64 = await Promise.all(
        acceptedFiles.map(file => Base64File.fromFile(file)),
      );

      if (acceptedFiles[0]) {
        const fileHeader = await getFileHeader(acceptedFiles[0]);

        const fileType = await checkFileType(fileHeader);
        if (fileType !== ExtensionNameEnum.TXT) {
          setInternalErrorState(
            ERROR_MESSAGES[InternalErrorsEnum.FILE_INVALID_TYPE],
          );
          acceptedFiles.splice(0, 1);
          return;
        }
      }
      const files = separeteCSVXLSFirefox(fileBase64, error);

      if (!multiple) {
        (onChange as (file: IDragAndDropFile, error?: string) => void)(
          files[0],
          error,
        );
      } else {
        const newValue = [...(value as IDragAndDropFile[])];

        if (replacingState !== undefined) {
          newValue.splice(replacingState, 1, ...files);
          setReplacingState(undefined);
        } else {
          newValue.push(...files);
        }

        (onChange as (files: MultipleFiles, error?: string) => void)(
          {
            files: newValue,
            fileChanged: files[0],
          },
          error,
        );
      }
    },
    [multiple, onChange, replacingState, value],
  );

  const getFileHeader = (file: File): Promise<Uint8Array> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result && reader.result instanceof ArrayBuffer) {
          const array = new Uint8Array(reader.result).subarray(0, 4);
          resolve(array);
        }
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const checkFileType = async (header: Uint8Array) => {
    const fileType = Object.entries(magicNumberMap).find(
      ([_, magic]) =>
        magic.length === 0 ||
        header.slice(0, magic.length).toString() === magic.toString(),
    );

    return fileType?.toString()
      ? fileType[0].toString()
      : ExtensionNameEnum.UNKNOWN;
  };

  const handleDeleteFile = useCallback(
    async (file: IDragAndDropFile) => {
      if (onDelete) {
        await onDelete(file);
      }
    },
    [onDelete],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: multiple !== false,
    maxFiles: typeof multiple === 'number' ? multiple : undefined,
    accept: mimeTypes,
    maxSize,
  });

  const DropAreaComponent = (
    <ArchiveArea>
      <SummaryCardNew.Container>
        <DropArea
          dropAreaStyles={dropAreaStyles}
          {...getRootProps({ refKey: 'innerRef' })}
          errorFile={!!error}
        >
          <Stack direction="column" alignItems="center">
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Icon name="file-document" size={24} />
              <DragFileText variant="text">
                <SelectFileText variant="text">
                  Selecione um arquivo
                </SelectFileText>{' '}
                ou arraste para essa área
              </DragFileText>
            </Stack>
          </Stack>
        </DropArea>
      </SummaryCardNew.Container>

      {!!error && (
        <ErrorArea
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          alignSelf="flex-start"
          spacing={0.5}
        >
          <ErrorIcon name="alert" size={18} />
          <ErrorMessage>
            <strong>Atenção!</strong> {error}
          </ErrorMessage>
        </ErrorArea>
      )}
    </ArchiveArea>
  );

  return (
    <Container
      ithaserror={itHasError ? 'true' : 'false'}
      containerstyles={containerStyles}
    >
      <InnerContainer
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {(isSubmittingFiles || isValidatingFiles) && (
          <SubmittingContent>
            <PulsarAnimationLoading width="32px" height="32px" />
            <SubmittingText>
              {isValidatingFiles
                ? 'Estamos finalizando a leitura, em instantes tudo estará pronto para você.'
                : 'Subindo arquivo...'}
            </SubmittingText>
          </SubmittingContent>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {title && <Heading>{title}</Heading>}
          <RightInfo>{rightInfo}</RightInfo>
        </Stack>
        {children && (
          <div style={{ marginTop: '8px', width: '100%' }}>{children}</div>
        )}
        <input {...getInputProps()} />
        <>
          {description && <Typography mb={'16px'}>{description}</Typography>}

          <InfoStack marginTop={0} direction="row">
            {hideFileSize && (
              <InfoItem
                sx={{
                  width: { xs: 'calc(50% - 8px)', sm: 'unset' },
                  pl: '8px',
                }}
              >
                <Title variant="text">Tamanho</Title>
                <Description>{formatBytes(maxSize)}</Description>
              </InfoItem>
            )}
          </InfoStack>
          <Stack gap={1}>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: { xs: 'center', sm: 'start' } }}
              mt={3}
            >
              Tudo pronto? Vamos começar.
            </Typography>
            <Typography variant="text" fontWeight={400}>
              Lembre-se selecione somente um arquivo, no formato <b>.txt</b> e
              com no máximo <b>8MB.</b>
            </Typography>
          </Stack>
        </>
        {(multiple || !hasFile) && DropAreaComponent}
        {isAvatar && hasFile && (
          <Stack direction="row" justifyContent="center" spacing={8}>
            {fileTypes?.length && (
              <Stack direction="column" spacing={2}>
                {description}
                <Title variant="text">
                  {fileTypes.length > 1 ? 'Formatos aceitos' : 'Formato'}
                </Title>
                <Description>{fileTypes.join(', ').toUpperCase()}</Description>
              </Stack>
            )}
            <Stack direction="column" spacing={2}>
              <Title variant="text">Tamanho</Title>
              <Description>{formatBytes(maxSize)}</Description>
            </Stack>
          </Stack>
        )}
        {isAvatar && hasFile && DropAreaComponent}
        {(!isSubmittingFiles || !isValidatingFiles) && (
          <>
            {files.length > 0 && !isAvatar && (
              <ArchiveArea gap={3}>
                {(multiple || !hasFile) && <DividerArea />}
                {files.map((file, index) => (
                  <SummaryCardNew.Container key={index}>
                    <Typography
                      variant="subtitle1"
                      sx={{ textAlign: { xs: 'center', sm: 'start' } }}
                    >
                      Arquivo de movimentação
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      key={index}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <ArchiveIcon size={24} name="file-check" />
                        <Stack>
                          <ArchiveTitle variant="text">
                            {truncateString(file.fileName, 40)}
                          </ArchiveTitle>
                          <ArchiveDescription>
                            {file.updatedAt || file.date ? (
                              <>
                                Realizado
                                <b>
                                  {` ${formatDateISO(
                                    file.updatedAt || file.date,
                                    "dd 'de' MMMM 'de' yyyy",
                                  )} • (${file.size})`}
                                </b>
                              </>
                            ) : (
                              file.createdAt && (
                                <>
                                  Realizado
                                  <b>
                                    {` ${formatDateISO(
                                      file.createdAt,
                                      "dd 'de' MMMM 'de' yyyy",
                                    )} • (${file.size})`}
                                  </b>
                                </>
                              )
                            )}
                          </ArchiveDescription>
                        </Stack>
                      </Stack>
                      <ActionArea
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        {onDownloadFileItem && (
                          <ActionButton
                            onClick={() => onDownloadFileItem(file)}
                          >
                            <ActionIcon size={24} name="download" />
                          </ActionButton>
                        )}
                        {!hideEditButton && (
                          <ActionButton
                            onClick={() => {
                              setReplacingState(index);
                              open();
                            }}
                          >
                            <ActionIcon size={24} name="edit" />
                          </ActionButton>
                        )}
                        {!!onDelete && (
                          <ActionButton onClick={() => handleDeleteFile(file)}>
                            <ActionIcon size={24} name="delete" />
                          </ActionButton>
                        )}
                      </ActionArea>
                    </Stack>
                  </SummaryCardNew.Container>
                ))}
              </ArchiveArea>
            )}

            {itHasError && (
              <ErrorArea
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignSelf="flex-start"
                spacing={1.5}
              >
                <ErrorIcon name="alert" size={18} />
                <ProcessedFileErrorMessageArea>
                  {processedFileError}
                </ProcessedFileErrorMessageArea>
              </ErrorArea>
            )}
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default DragAndDrop;
