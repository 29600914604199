import React from 'react';
import { Header, StyledGrid } from './styles';
import HapLogo from '../../assets/images/hapvida-logo-new.svg';
import NDILogo from '../../assets/images/ndi-logo-new.svg';
import UserMenu from '../UserMenu';
import type { HeaderMinimalProps } from './types';

const HeaderMinimal: React.FC<HeaderMinimalProps> = ({
  user,
  logOut,
  loginRoute,
  onClickLogo,
}) => {
  return (
    <Header>
      <StyledGrid
        onClick={onClickLogo}
        container
        item
        alignItems="center"
        gap={2}
        hasClick={Boolean(onClickLogo)}
      >
        <img src={HapLogo} alt="Logo Hapvida" />
        <img src={NDILogo} alt="Logo NDI" />
      </StyledGrid>
      {user ? (
        <UserMenu username={user.name} role={user.profile} onLogOut={logOut} />
      ) : (
        <span>
          Já tem cadastro?
          <strong>
            <a href={loginRoute}>Efetue o login</a>
          </strong>
        </span>
      )}
    </Header>
  );
};

export default HeaderMinimal;
